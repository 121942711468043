.brick {
    min-width: 320px;
    display: flex;
    flex-direction: column;
    padding: 15px!important;
    position: relative;
    width: 75%;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    transition: color .15s
    ease-in-out,background-color .15s;
    overflow: hidden;
    margin: 0 auto 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /*background-color: #f6c101!important;*/
}

.logo-holder img {
    max-height: inherit;
    margin: auto;
    max-width: inherit;
}

.logo-holder.location {
    overflow-x: hidden;
    overflow-y: hidden;
    justify-content: center;
}