.list-holder {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    min-height: 80%;
    background:
        /* Shadow covers */
            linear-gradient(white 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,

                /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

.list-holder.empty {
    align-items: center;
    display: flex;
    justify-content: center;
}

.add-button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 5px;
    padding: 1px 7px 2px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.button-container {
    width: 100%;
}

.animated {
    width: 500px;
    height: 500px;
}