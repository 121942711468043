.App {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  z-index: 0;
}

.add-to-home-banner {
  position: relative!important;
}

.content {
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
}