.section {
    margin: auto;
}

.edit-button {
    /*margin: 5px;*/
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
}

.brick.venue-brick {
    border-radius: .3rem;
    background-color: #e9ecef;
    width: auto;
}

.brick.first {
    border-color: gold;
    background-color: palegoldenrod;
    border-style: solid;
    border-width: 2px;
}

.brick.first:hover {
    background-color: #e8dc6b;
}

.brick.second {
    border-color: silver;
    background-color: #E2E2E2;
    border-style: solid;
    border-width: 2px;
}

.brick.second:hover {
    background-color: #cfcfcf;
}

.brick.third {
    border-color: #cc6633;
    background-color: #cf8b48;
    border-style: solid;
    border-width: 2px;
}

.brick.invalid {
    border-color: darkred;
    background-color: lightcoral;
}

.brick.third:hover {
    background-color: #cd7f32;
}

.beer-logo {
    margin: 10px;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
}

.edit-button:focus,.edit-button:active {
    outline: transparent none 0 !important;
    box-shadow: none;
}

.place-div {
    position: absolute;
    top: 0;
    left: 0;
}

.edit-button-div {
    position: absolute;
    top: 0;
    right: 0;
}