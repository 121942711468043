.check-mark {
    -webkit-animation: fadein .4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein .4s; /* Firefox < 16 */
    -o-animation: fadein .4s; /* Opera < 12.1 */
    animation: fadein .4s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}