.title-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title-header:hover {
    cursor: pointer;
}

.dropdown-container {
    border-bottom: darkgray 1px solid;
    padding: 10px;
}

.dropdown-toggle-1{
    transition: all .2s ease;
}

.dropdown-toggle-1.down {
    transform: rotate(0deg);
}

.dropdown-toggle-1.up {
    transform: rotate(180deg);
}

.dropdown-content-container {
    width: 100%;
    padding-top: 10px;
}