.upload-area {
    width: 75%;
    height: 50%;
    border-width: 2px;
    border-style: dashed;
    border-radius: .25rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border-color: #eeeeee;
    color: #bdbdbd;
}

.upload-area.mobile {

}

.upload-area.hidden {
    display: none;
}

.page {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.page-header {
    width: 100%;
    height: 10%;
    overflow: hidden;
}

.selected-image-holder {
    width: 100%;
    height: 90%;
    display: -webkit-flex;
    flex-direction: column;
}

.selected-image-holder.hidden {
    display: none;
}

.selected-image-header {
    flex: 1 1 auto;
}

.image {
    max-height: 100%;
}

.button-holder {
    flex: 1 1 auto;
    margin: 5px;
}

.image-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    flex: 1 1 auto;
}

.control-btn {
    margin: 10px;
}

.upload-error {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: hidden;
    color: red;
}