.pass-show-hide-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .eye-icon-holder {
    cursor: pointer;
    margin: 5px;
  }
}