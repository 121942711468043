.near-you-nav {
    justify-content: center;
    flex: 0 1 auto;
    width: 100%;
}

.near-you-search-settings {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
}

.filter-button {
    position: absolute;
    left: 0;
    margin: 5px;
    z-index: 100;
}

.near-you-page-content {
    flex: 1 1 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
}

.filter-settings-holder {
    border: lightgrey 1px solid;
    border-radius: .25rem;
}

.filter-settings {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.slider {
    height: 100%;
    width: 200px;
}

.items-holder {
    padding-top: 10px;
}

.spinner {
    margin: auto;
}

.clickable {

}

.clickable:hover {
    cursor: pointer;
}

.list-holder {
    position: -webkit-sticky;
    position: sticky;
    overflow-y: scroll;
    overflow-x: hidden;
}

.setting {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 5px;
}

.range-holder {
    flex: 0 0 100%;
}

.setting input {
    margin-left: 7px;
}

.range-slider {
    flex: 0 0 auto;
    margin: 15px;
    max-width: 50%;
}