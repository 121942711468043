.notification {
    justify-content: center;
    margin: 5px auto;
    width: 50%;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notification-center {
    width: 100%;
    position: absolute;
    z-index: 2000;
}

.notification-center.active {
    top: 0;
}

.notification-center.hidden {
    bottom: 0;
}

.close-button {
    color: darkgray;
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
}

.close-button:hover {
    cursor: pointer;
    color: dimgray;
}

.notification.mobile {
    width: 95%;
}

.notification.clickable {

}

.icon-holder {
    align-self: center;
}

.notification.clickable:hover {
    cursor: pointer;
}

.dismiss-button {
    align-self: center;
}

.text-holder {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    text-align: left;
}

.text-holder p {
    margin-bottom: 0;
}