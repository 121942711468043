.venue-info-holder {
    margin: auto;
    border-right: darkgray 1px solid;
    padding: 10px;
    /*font-size: 2.5vw;*/
}

.beer-info-heading {
    justify-content: center;
    height: 15%;
    width: 100%;
    margin: auto;
}

.beer-list-holder {
    justify-content: center;
    height: 85%;
    width: 100%;
    margin: auto;
}

.beer-info-holder {
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
}

.beer-holder {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin: auto;
    max-height: 100%;
    min-height: 0;
    width: 100%;
    align-content: stretch;
    align-items: center;
    flex-wrap: wrap;
}

.beer-text {
    flex: 1 1 auto;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    padding: 2px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 16px;
}

.beer-text h3 {
    margin-bottom: 0;
}

.beer-text h4 {
    margin-bottom: 0;
}

.beer-text h5 {
    margin-bottom: 0;
}

.beer-text h6 {
    margin-bottom: 0;
}

.beer-text p {
    margin-bottom: 0;
}



.badge-holder {
    flex: 0 1 15%;
    align-self: flex-end;
    margin: auto;
    justify-content: center;
    display: inherit;
    font-size: 14px;
}

.venue-info-holder h4 {
    margin-bottom: 3px;
}

.venue-info-holder p {
    margin-bottom: 3px;
}

.logo-holder.location {
    max-width: 60px;
}

.venue-info-holder.mobile {
    border-right: 0;
}