.page-content {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    overflow-y: scroll;
}

.intro-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
}

.home-info-item {
    height: 400px;
    padding-top: 10px;
    display: flex;
    padding-right: 5px;
    padding-left: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-info-item p {
    margin: 0 auto;
    max-width: 32.4102564rem;
}

.body {
    text-align: left;
    text-indent: 25px;
}