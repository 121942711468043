.venue-name-holder {
    display: flex;
    flex-direction: row;
}

.venue-controls {
    height: 100%;
}

.current-venue-content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 0 100%;
    background-color: #f8f9fa;
}

.beers-list {
    padding-top: 5px;
}

.top-row {
    background-color: white;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.no-venue {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}