.clickable:hover {
    cursor: pointer;
}

.clickable {

}

.navbar-brand {
    display: flex;
    align-items: center;
}
.navbar-brand>img {
    padding: 7px 14px;
    height: 50px;
}

.brand {
    color: #f6c101!important;
    height: 100%;
}

.selected-nav {
    border-bottom: solid 3px lightgrey;
}